Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage2";
exports.labelBodyText = "LandingPage2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.urlGetValidations = "profile/validations";
exports.ApiContentType = "application/json";
exports.methodGET = "GET";
exports.loginAPiEndPoint = "login/login";
exports.methodPOST = "POST";
exports.methodDELETE = "DELETE";
exports.loginApiContentType = "application/json";

exports.goalsEndpoint = "bx_block_pricingcalculator/goals";
exports.advancedGoalsEndpoint = "bx_block_pricingcalculator/goals/create_advanced_goals";

exports.expensesEndpoint = "bx_block_pricingcalculator/expenses"
exports.advancedExpensesEndpoint = "bx_block_pricingcalculator/expenses/create_advanced_expenses"

exports.clientInfosEndpoint = "bx_block_pricingcalculator/clientinfos"
exports.advancedClientInfosEndpoint = "bx_block_pricingcalculator/clientinfos/create_advanced_client_info"
// client information
exports.updateOfPackage="bx_block_custom_packages/update_package_arrangement"
exports.clientInformationEndpoint = "bx_block_pricingcalculator/client_informations"
exports.roadmapEndpoint = "bx_block_pricingcalculator/road_customizations"
exports.packageEndpoint = "bx_block_pricingcalculator/package_pricings"
exports.advancedCalculationsEndpoint = "bx_block_pricingcalculator/goals/advance_goal/advance_goal_calculation"
exports.defaultRoadmapEndpoint = "road_customizations/default_road_customization"

//package list endpoint
exports.packageListEndpoint = "bx_block_contentmanagement/package_managements";

//profile page endpoints
exports.profileCustomizationEndpoint = "accounts/profile_customization";

//custom packages page endpoint
exports.savedCustomPackagesEndpoint = "bx_block_contentmanagement/custom_packages";

//categories endpoint
exports.categoriesEndpoint = "categories/categories";
exports.subCategoriesEndpoint = "categories/sub_categories";
exports.customPackageEndpoint = "bx_block_custom_packages/custom_package_creation";
exports.packageList = [
  {
    packageName: "New Business Incubator",
    packageType: "business",
    revGoalPercent: "revGoalNewBusinessIncubator",
    percentOfClient: "percentOfClientsNewBusinessIncubator",
    timePeriod: "per unit",
    id: "New Business Incubatorbusiness",
  },
  {
    packageName: "Business Bundle",
    packageType: "business",
    revGoalPercent: "revGoalBusinessBundle",
    percentOfClient: "percentOfClientsBusinessBundle",
    timePeriod: "per month",
    id: "Business Bundlebusiness",
  },
  {
    packageName: "Business Tax & Advisory",
    packageType: "business",
    revGoalPercent: "revGoalBusinessTax",
    percentOfClient: "percentOfClientsBusinessTax",
    timePeriod: "per month",
    id: "Business Tax & Advisorybusiness",
  },
  {
    packageName: "Accounting Success",
    packageType: "business",
    revGoalPercent: "revGoalAccountingSuccess",
    percentOfClient: "percentOfClientsAccountingSuccess",
    timePeriod: "per month",
    id: "Accounting Successbusiness",
  },
  {
    packageName: "Payroll Success",
    packageType: "business",
    revGoalPercent: "revGoalPayrollSuccess",
    percentOfClient: "percentOfClientsPayrollSuccess",
    timePeriod: "per month",
    id: "Payroll Successbusiness",
  },
  {
    packageName: "Reporting-Only Package",
    packageType: "business",
    revGoalPercent: "revGoalReportingOnlyBusiness",
    percentOfClient: "percentOfClientsReportingOnlyBusiness",
    timePeriod: "per unit",
    id: "Reporting-Only Packagebusiness",
  },
  {
    packageName: "Personal Bundle",
    packageType: "Individual",
    revGoalPercent: "revGoalPersonalBundle",
    percentOfClient: "percentOfClientsPersonalBundle",
    timePeriod: "per month",
    id: "Personal BundleIndividual",
  },
  {
    packageName: "IRS Rapid Relief",
    packageType: "Individual",
    revGoalPercent: "revGoalIRSRapidRelief",
    percentOfClient: "percentOfClientsIRSRapidRelief",
    timePeriod: "per unit",
    id: "IRS Rapid ReliefIndividual",
  },
  {
    packageName: "Reporting-Only Package",
    packageType: "Individual",
    revGoalPercent: "revGoalReportingOnlyIndividual",
    percentOfClient: "percentOfClientsReportingOnlyIndividual",
    timePeriod: "per unit",
    id: "Reporting-Only PackageIndividual",
  },
];
exports.googleApi = 'bx_block_gsuiteintegration/gsuites'
exports.getRoadmap = 'bx_block_pricingcalculator/road_customizations'
exports.getClientDetail = 'bx_block_pricingcalculator/client_informations'
exports.additionalUserEndpoint = "bx_block_admin/show_employee"
exports.strategicRateHeading  = "Strategic Rate";
exports.contractPeriodHeading = "Contract Period";
exports.yourPricePointsHeading = "Your Price Points"
exports.defaultHeading =  "Default";
exports.strategicRatesHeading = "Strategic Rates";
exports.modifyHeading = "Modify?";
exports.dollarSign = "$";
exports.savingPercent = "(Save 20%)"
exports.monthlyHeading = "Monthly"
exports.yearlyHeading = "Yearly"
exports.advisePlanHeading = "Select Advise Plan"
exports.engagePlanHeading = "Select Engage Plan"
exports.acceleratePlanHeading =  "Select Accelerate Plan"
exports.monthlyPlan = "(Monthly)"
exports.additionalPlanHeading = "Additional Users :"
exports.digitalMarketingHeading = "Digital Marketing Makeover:"
exports.additionPlanText = "1 User is included with the subscription. I want - 1 + additional user(s) for $40/month each"
exports.digitalMarketingText = "Custom Website, Custom Client Guide, & Custom Package Flyer for $2495"
exports.adviseHeading =  "Advise";
exports.engageHeading =  "Engage";
exports.accelerateHeading = "Accelerate";
exports.cancelSubscriptionButtonText = "Cancel My Subscription";
exports.manageSubscriptionButtonText = "Reset To My Current Subscription";
exports.confirmSubscriptionButtonText = "Confirm Changes To My Subscription";
exports.advisePrice = "$225/month";
exports.engagePrice = "$95/month";
exports.acceleratePrice = "$625/month";
exports.adviseDescription = "When you need a proven process to deliver client advisory work.";
exports.engageDescription = "When you’re ready to increase margins & stop giving away.";
exports.accelerateDescription = "When you want the fastest possible results with expert help.";
exports.mySubscription  = "MY SUBSCRIPTION";
// Customizable Area End
